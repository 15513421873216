
.manual_row {
  background: #FCD6D9 0% 0% no-repeat padding-box;
}
.mt10 {
  margin-top: 10px;
}
.color-red label {
  color: #fb434a !important;
}
